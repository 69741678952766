<template>
  <main class="no-main">
    <div class="ps-breadcrumb">
      <div class="container-fluid-sy">
        <ul class="ps-breadcrumb__list">
          <li class="active">
            <router-link :to="{ name: 'home' }">Home</router-link>
          </li>
          <li><a href="javascript:void(0);">Dashboard</a></li>
        </ul>
      </div>
    </div>

    <section class="section--single-post ps-page--business">
      <div v-if="!isVerified">
        <div class="alert alert-warning is-verify" v-if="!alert">
          We now need verify your email address. we've sent an email to
          <span><i class="mail-address-alert">{{ user.email }}</i></span> to verify your address please click the link
          in that email to continue
          <button type="button" class="close">
            <span class="close" v-on:click="alert = true">&times;</span>
          </button>
          <div>
            <a class="resend" type="submit" href="javascript:void(0)" 
                      @click="resentCode(emailResent.email)">
              Resend
            </a>
          </div>
        </div>
        
        </div>
      <div class="container">        
        <div class="post__content">
          <div class="post__blog">
            <h2 class="post__title">Dashboard</h2>
            <div class="row m-0">
              <div class="col-12 col-lg-4 p-0">
                <router-link :to="{ name: 'user-orders-history' }">
                  <div class="box--icon">
                    <div class="block__icon"><i class="icon-cart"></i></div>
                    <div class="block__content">
                      <p><span class="title__block">Orders</span></p>
                      <p>View your orders</p>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col-12 col-lg-4 p-0">
                <router-link :to="{ name: 'wishlist' }">
                  <div class="box--icon">
                    <div class="block__icon"><i class="icon-heart"></i></div>
                    <div class="block__content">
                      <p><span class="title__block">Wishlist</span></p>
                      <p>View your wishlists</p>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col-12 col-lg-4 p-0">
                <router-link :to="{ name: 'address' }">
                  <div class="box--icon">
                    <div class="block__icon"><i class="icon-location"></i></div>
                    <div class="block__content">
                      <p><span class="title__block">Addresses</span></p>
                      <p>View your addresses</p>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-12 col-lg-4 p-0">
                <router-link :to="{ name: 'account-setting' }">
                  <div class="box--icon">
                    <div class="block__icon"><i class="icon-user"></i></div>
                    <div class="block__content">
                      <p><span class="title__block">Account details</span></p>
                      <p>View your details</p>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col-12 col-lg-4 p-0">
                <router-link :to="{ name: 'cart' }">
                  <div class="box--icon">
                    <div class="block__icon"><i class="icon-star"></i></div>
                    <div class="block__content">
                      <p><span class="title__block">View Cart</span></p>
                      <p>View your cart</p>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col-12 col-lg-4 p-0">
                <div
                  class="box--icon tc"
                  v-if="seen"
                  v-on:click="
                    seen = !seen;
                    hide = !hide;
                  "
                >
                  <div class="block__icon"><i class="icon-bullhorn"></i></div>
                  <div class="block__content">
                    <p><span class="title__block">Subscription</span></p>
                    <p>Subscription</p>
                  </div>
                </div>
                <!-- // -->
                <div class="box--icon" v-if="!seen">
                  <div class="block__icon"><b>Subscribe ?</b></div>                                               
                  <div class="block__content">   
                    <form >                                   
                    <label class="switch">                     
                      <input type="checkbox" v-model="toggle"  
                      v-bind:value="user.subscribed_to_news_letter" v-bind:true-value="1" v-bind:false-value="0"
                      :checked="user.subscribed_to_news_letter"
                      @change="subscribeMail(user.email, toggle)">
                      <span class="slider round"></span>
                    </label>  
                    </form>
                      

                    <!-- <form @submit.prevent="subscribeMail()">
                    <label class="yorn mr-2">Yes</label>
                    <input class="mr-2" v-model="formEmail.subscriber_email"  type="radio" name="sub" v-bind:value="user.email"/>
                    <label class="yorn mr-2">No</label>
                    <input class="mr-2" v-model="formEmail.subscriber_email" type="radio" name="sub" v-bind:value="user.email"/>
                    in button
                     v-on:click="
                        seen = !seen;
                        hide = !hide;
                      "
                    <button                     
                      class="btn btn-success ml-4"
                      type="submit"                      
                    >
                      Save
                    </button>
                    </form> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <ChatBox />
  </main>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import ChatBox from "../Components/ChatBox";
export default {
  name: "Dashborad",
  data() {
    return {
      seen: true,
      hide: false,
      alert:false,
      formEmail: {
        subscriber_email: "",
      },
      subscribed_to_news_letter: true,
      emailResent: {
        email: "",
      },
    };
  },
  mounted() {
    if (this.user.email) {
      this.emailResent.email = this.user.email;
    }
  },
  components: {
    ChatBox,
  },

  computed: {
    ...mapGetters("auth", ["user", "isVerified"]),
  },
  methods: {
    ...mapActions("auth", ["subscribeEmail", "unSubscribe", "resendVerifycode"]),
    check: function() {
		// console.log(this.checked)
    },

    subscribeMail(user_mail, is_subcribe) {
      this.formEmail.subscriber_email = user_mail;
      if (is_subcribe) {
        this.subscribeEmail(this.formEmail).then(() => {  });
      }else{
        this.unSubscribe(this.formEmail).then(() => {  });
      }
    },

    resentCode() {
      this.resendVerifycode(this.emailResent);
    },
  },
};
</script>
<style lang="scss" scoped>
.tc {
  cursor: pointer;
}
.icon-cross{
  margin-left: 160px;
}
.alert{
  text-align: center;
  padding: 14px;
}
.alert .close{
  font-size: 23px;
}
.alert .mail-address-alert{
  font-weight: 540;
}
// button
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 22px;
  margin-left: 8px;
  margin-top: 12px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 1px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #80990B;
}

input:focus + .slider {
  box-shadow: 0 0 1px #80990B;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.resend{
  border: 1px solid #FF7200;      
  color: #FF7200;
  margin-right: 8px;
  font-size: 12px;
}
.is-verify a {
    display: inline-block;
    padding: 3px 6px;
    margin-top: 10px;
}
.resend:hover {
    color: #fff;
    background: #FF7200;
}
</style>